.question {
    border: 1px solid black;
    padding: 20px;
    margin: 20px;
}
.question.active {
    border: 3px solid rgba(178, 71, 255, 1);
}
.submitted {
    color: rgba(178, 71, 255, 1);
    font-weight: bold;
    margin-top: 20px;
}
.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 30px;
    background-color: rgba(178, 71, 255, 0.84);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.submit-button:hover {
    background-color: rgba(178, 71, 255, 1);
    border: 1px solid rgb(126, 0, 215);
}
.link {
    color: rgba(178, 71, 255, 0.7);
    padding-left: 20px;
    text-decoration: underline;
    cursor: pointer;
}
.link:hover {
    color: rgba(178, 71, 255, 1);
    font-weight: bold;
}
.question-explanation {
    padding: 10px;
    margin-bottom: 10px;
}