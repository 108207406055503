.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f2f2f2;
  color: #333;
}

.current-user {
  font-weight: bold;
  border: 3px solid #33f;
  background-color: #eef;
}

/* For ScoreTable */
.name-cell {
  cursor: pointer;
}
.name-cell:hover {
  text-decoration: underline;
}
