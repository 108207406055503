.topUserInfo {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 0 auto;
  font-size: 8pt;
  background-color: #eef;
}
.roomInfo {
  padding: 10px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 8pt;
  background-color: #eef;
}
.App {
  margin: 5px;
  font-family: sans-serif;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}
.button-style {
  margin: 10px;
  padding: 10px;
  font-size: 20px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #eef;
}
.button-style:hover {
  background-color: #ccf;
  cursor: pointer;
}
.startForm input {
  width: 80%;
}
input[type=checkbox] {
  transform: scale(1.2);
}